// [PROD] Manday 2024
export const sheetUrl = 'https://sheet.best/api/sheets/3f549061-07bd-49b3-b99c-02ed8015e4dc'
// [PROD] Day Off 2024
export const leaveSheetUrl = 'https://sheet.best/api/sheets/e549a587-1c16-4d53-a172-50c118702469'

// [PROD] White board Sheet
export const whiteBoardSheetUrl = 'https://sheet.best/api/sheets/bfc697e3-4986-4ee4-9e92-ddbb9200110f'

export const trackingUrl = 'https://tracking.ksta.co/'
export const secretPass = 'password1234'

export const middleware = 'https://tracking-notify.ksta.co/sheet'

// key must be 64 char
export const encryptionKey = 'mNGp6fxDgU4fZXMwtCNBGfLUnNlH7bIuNoOqVFIOZlm3vb4dePWQYGyqcks67Q4m'
